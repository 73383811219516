import { Row, Col, Form, Button } from 'react-bootstrap';
import React, { useState, useMemo, useCallback, Fragment, useEffect } from "react";
import * as Yup from "yup";
import * as apiHelper from "../../helper/api_helper";
import * as helper from "../../helper/sessionStorage";
import { useSelector, useDispatch } from "react-redux";
import { setUserDetailsLoading, updateUserDetails } from "../../store/action";
import { defaultPopUpState, TimeDisplay } from '../../helper/project_helper';
import { updateAuthPoupState } from "../../store/action";
import PageLoaderComponent from "../PageLoaderComponent";
import { useAuth } from "../hooks/useAuth";
import OtpInput from 'react-otp-input';

export const VerificationCode = ({
    dontWantLogin,
    closeAllPopUp,
    showSignIn
}) => {
    const [isLogin, userData] = useAuth();

    const [formValues, setFormValues] = useState(false);
    const [expireTime, setExpireTime] = useState(5 * 60);
    const [loginFailed, setLoginFailed] = useState(false);
    const [loginFailedErrorMsg, setLoginFailedErrorMsg] = useState(false);
    const [otp, setOtp] = useState('');
    const dispatch = useDispatch();


    useEffect(() => {
        // If timeLeft is 0, stop the timer
        if (expireTime === 0) {
            dontWantLogin();
            return;
        }

        // Set an interval to decrease timeLeft every second
        const intervalId = setInterval(() => {
            setExpireTime((prevTime) => prevTime - 1);
        }, 1000);

        // Cleanup the interval on component unmount or when timeLeft is updated
        return () => clearInterval(intervalId);
    }, [expireTime]);

    const minutes = Math.floor(expireTime / 60);
    let seconds = expireTime % 60;
    const { stateUserDetail, statePopUpData } = useSelector((state) => ({
        stateUserDetail: state.UserDetails,
        statePopUpData: state.AuthPopupState,
    }));

    useEffect(() => {
        if (otp.length === 6) {
            handleSubmit();
        }
    }, [otp]);

    const loginSuccessCallback = useCallback(() => {
        if (loginSuccessCallback !== null) {
            dispatch(updateAuthPoupState(defaultPopUpState))
        }
        return statePopUpData?.signInSuccessCallback || null;
    }, [statePopUpData]);

    const validationSchema = Yup.object({
        verification_code: Yup.string()
            .required('Verification code is required'),  // Ensures the field is required
    });

    const handleSubmit = () => {
        userSignInSignUp();
    }

    // setTimeout(() => {
    //     setExpireTime(expireTime-0.01)
    // }, 1000);
    async function userSignInSignUp() {

        dispatch(setUserDetailsLoading(true));
        const tempData = stateUserDetail?.tempData;
        const combinedParms = { ...tempData, verification_code: otp };

        try {
            let response = false;
            if (combinedParms?.is_login === '1') {
                response = await apiHelper.postUserSignIn(combinedParms);
            } else {
                response = await apiHelper.postUserSignUp(combinedParms);
            }

            let userData = false;
            let isLogin = false;
            if (response?.data?.status === 1) {
                userData = response.data.body;
                isLogin = true;
                helper.setLoginUserData(userData);
                setLoginFailed(false);
                setLoginFailedErrorMsg(false);
                dispatch(updateUserDetails({
                    loading: false,
                    status: true,
                    data: userData,
                    isLogin: isLogin
                }));
                let getCallbackMethodFunc = loginSuccessCallback();

                if (getCallbackMethodFunc != null) {
                    getCallbackMethodFunc(true);
                }
            } else {
                setLoginFailed(true);
                setLoginFailedErrorMsg(response?.data?.msg);
                dispatch(setUserDetailsLoading(false));
                // setTimeout(() => {
                //     setLoginFailed(false);
                //     setLoginFailedErrorMsg(false);
                // }, 3000)
            }
        } catch (error) {
            console.error(error);
            dispatch(updateUserDetails({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }

    async function reSendAuthCode() {
        setLoginFailed(false);
        setLoginFailedErrorMsg(false);
        dispatch(setUserDetailsLoading(true));
        const tempData = stateUserDetail?.tempData;
        try {
            let response = false;
            response = await apiHelper.postSendAuthCode(tempData);
            if (response?.data?.status === 1) {
                setOtp('');
                document.querySelectorAll('.otp-input')[0].focus();
                setExpireTime(5 * 60);
                dispatch(setUserDetailsLoading(false));
                setLoginFailed(true);
                setLoginFailedErrorMsg("Verification code resent successfully!");
                dispatch(setUserDetailsLoading(false));
                setTimeout(() => {
                    setLoginFailed(false);
                    setLoginFailedErrorMsg(false);
                }, 5000)
            } else {
                setLoginFailed(true);
                setLoginFailedErrorMsg(response?.data?.msg);
                dispatch(setUserDetailsLoading(false));
                setTimeout(() => {
                    setLoginFailed(false);
                    setLoginFailedErrorMsg(false);
                }, 3000)
            }
        } catch (error) {
            console.error(error);
            dispatch(updateUserDetails({
                loading: false,
                status: false,
                error: error,
            }));
        }
    }


    useEffect(() => {
        setTimeout(() => {
            document.querySelectorAll('.otp-input')[0].focus();
        }, 100);
    }, []);

    return (
        <React.Fragment>
            <section className='px-lg-5 px-3 banner-layout-popup signin-width'>
                <div className='popup-close pt-1'>
                    <svg style={{ cursor: "pointer" }} onClick={() => {
                        setLoginFailed(false);
                        setLoginFailedErrorMsg(false);
                        showSignIn();
                    }
                    } width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                </div>
                <PageLoaderComponent loading={stateUserDetail?.loading} />
                <p className='pb-2 pt-3 font-px font-w text-center'>Please enter the verification code sent to your email address.</p>
                <Form className="mt-3">
                    <Row className="mb-3">
                        <Col xs="12" controlId="validationCustom02" className="mb-3">
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                containerStyle={"otp-container"}
                                inputStyle={"form-control otp-input"}
                                renderSeparator={<span>&nbsp;-&nbsp;</span>}
                                renderInput={(props) => <input {...props} />}
                                inputType="tel"
                            />
                        </Col>
                    </Row>
                    {loginFailedErrorMsg && (
                        <span className='warning-msg mb-2 font-w' style={{ display: "flex", justifyContent: "center" }}>{loginFailedErrorMsg}</span>
                    )}
                    <span className='font-midium font-w' style={{ display: "flex", justifyContent: "center" }}>Code will expire in {minutes}:{seconds.toString().padStart(2, '0')} minutes.</span>

                    <div className="text-center pb-4">
                        <button
                            type="button"
                            onClick={reSendAuthCode}
                            className="btn-link bg-transparent border-0 font-black font-small font-w green-hover-text"
                        >Resend verification code</button>
                    </div>
                </Form>
            </section>
        </React.Fragment>
    )
}
