import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useSelector, useDispatch } from "react-redux";
import { defaultPopUpState } from "../../helper/project_helper";
import * as apiHelper from "../../helper/api_helper";
import { updateAuthPoupState, updateUserDetails } from "../../store/action";
import { clearLoginUserData } from "../../helper/sessionStorage";

export const AuthButton = ({ onLogin, children, className, ...props }) => {
    const [isLogin, userData] = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    if(userData !== undefined)
    {
        
    }

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.statePopUpData,
    }));

    const dispatch = useDispatch();

    const CheckLogin = async () => {

        if (!isLogin) {
            dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
                onLogin(cc);
            }));
        } else {
            setIsLoading(true);
            const response = await apiHelper.postCheckLogin();
            if (response?.data?.status === 1) {
                onLogin(true);
                setIsLoading(false);
            } else if (response?.data?.status === 2) {
                setIsLoading(false);
                clearLoginUserData();
                dispatch(updateUserDetails(false));
                dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }, function (cc) {
                    onLogin(cc);
                }));
            }
        }
    }


    useEffect(() => {
        if (statePopUpData?.signInSuccessCallback) {
            onLogin(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statePopUpData?.signInSuccessCallback]);

    return (
        <React.Fragment>
            <button disabled={isLoading} onClick={(x) => {
                CheckLogin();
            }} className={className}>
                {children}
            </button>
        </React.Fragment>
    )
}


// setState({...defaultPropsLogin });
