import React, { Fragment } from "react";
import { Row, Col } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { NumberWithThousandSeparator, formatDecimal, isEmpty, handleDragStart, handleContextMenu } from "../../helper/project_helper";
import { withRouter } from "react-router-dom";

const ModelDetailSection = ({
    incentiveBuyDetailData,
    expandedMoreFeature,
    setExpandedMoreFeature,
    expandedRows,
    chargingDetail,
    isLoading
}) => {
    return (
        <Fragment>
            <section className="mx-lg-5 mx-3">
                {incentiveBuyDetailData &&
                    <div className="model-detail">
                        <Row className="align-items-center">
                            <Col lg={3} className="d-flex flex-column align-items-center m-view">
                                <div className="detail-img">
                                    <img
                                        src={process.env.REACT_APP_VEHICLE_BASE_URL + incentiveBuyDetailData?.buy?.make?.replace(/-/g, '+') + "/" + incentiveBuyDetailData?.buy?.model_year + "/" + incentiveBuyDetailData?.buy?.vehicle_image_link?.replace(/\+/g, '%2B')}
                                        onContextMenu={handleContextMenu}
                                        onDragStart={handleDragStart}
                                        // alt={incentiveBuyDetailData?.buy?.make + " " + incentiveBuyDetailData?.buy?.model+ " " + incentiveBuyDetailData?.buy?.trim + " " + incentiveBuyDetailData?.buy?.model_year}
                                        alt={`Current ${incentiveBuyDetailData?.buy?.model_year} ${incentiveBuyDetailData?.buy?.make} ${incentiveBuyDetailData?.buy?.model} ${incentiveBuyDetailData?.buy?.segment_name} Lease, Deals, Offers and Rebate`}
                                    />
                                </div>
                                <div className=" text-font text-center font-wxl font-black">Source: {incentiveBuyDetailData?.buy?.vehicle_image_source}</div>
                            </Col>
                            <Col lg={9} className="">
                                <Row>
                                    <Col md={2} sm={4} xs={4} className="text-center">
                                        <h2 className="vehicle-detail-list">{incentiveBuyDetailData?.buy?.segment_name}</h2>
                                        <span className="text-font font-w4 font-black">Segment</span>
                                    </Col>
                                    <Col md={2} sm={4} xs={4} className="text-center">
                                        <h2 className="vehicle-detail-list">{incentiveBuyDetailData?.buy?.range_low === incentiveBuyDetailData?.buy?.range_high ? incentiveBuyDetailData?.buy?.range_low : incentiveBuyDetailData?.buy?.range_low + "-" + incentiveBuyDetailData?.buy?.range_high} mi</h2>
                                        <span className="text-font font-w4 font-black">Est. Range</span>
                                    </Col>
                                    <Col md={2} sm={4} xs={4} className="text-center">
                                        <h2 className="vehicle-detail-list">${incentiveBuyDetailData?.buy?.base_msrp
                                            ? NumberWithThousandSeparator(incentiveBuyDetailData?.buy?.base_msrp)
                                            : 0}</h2>
                                        <span className="text-font font-w4 font-black">Base Price</span>
                                    </Col>
                                    {incentiveBuyDetailData?.buy?.seating && (
                                        <Col md={2} sm={4} xs={4} className="text-center">

                                            <h2 className="vehicle-detail-list">{incentiveBuyDetailData?.buy?.seating}</h2>
                                            <span className="text-font font-w4 font-black">Seats</span>
                                        </Col>
                                    )}
                                    {!isEmpty(incentiveBuyDetailData?.buy?.acceleration) && (
                                        <Col md={2} sm={4} xs={4} className="text-center">
                                            <h2 className="vehicle-detail-list">{formatDecimal(incentiveBuyDetailData?.buy?.acceleration)}s</h2>
                                            <span className="text-font font-w4 font-black">0-60mph</span>
                                        </Col>
                                    )}

                                    {incentiveBuyDetailData?.buy?.cargo_space && (
                                        <Col md={2} sm={4} xs={4} className="text-center">
                                            <h2 className="vehicle-detail-list">{incentiveBuyDetailData?.buy?.cargo_space} cu ft</h2>
                                            <span className="text-font font-w4 font-black">Cargo Space</span>
                                        </Col>
                                    )}
                                </Row>
                                {(!isEmpty(incentiveBuyDetailData?.buy?.driver_assist) || !isEmpty(incentiveBuyDetailData?.buy?.active_safety) || !isEmpty(incentiveBuyDetailData?.buy?.self_driving) || !isEmpty(chargingDetail?.bi_directional_charging) || !isEmpty(incentiveBuyDetailData?.buy?.self_driving)) &&
                                    <Col lg={12} className="more-feature" style={{ position: "relative", top: "63px" }}>
                                        <div className="d-flex justify-content-end">
                                            <button className="text-end tag-more button-set" onClick={(s) => {
                                                setExpandedMoreFeature(!expandedMoreFeature)
                                            }}>
                                                More Features
                                                {expandedMoreFeature ?
                                                    (<i className="ms-2"><svg className="minus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="#1E1E1F" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3m0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5m-6 10v2h12v-2z" /></svg></i>) :
                                                    (<i className=" ms-2"><svg className="plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="#1E1E1F" d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13S23.168 3 16 3m0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16S9.913 5 16 5m-1 5v5h-5v2h5v5h2v-5h5v-2h-5v-5z" /></svg></i>)
                                                }
                                            </button>
                                        </div>
                                    </Col>
                                }
                            </Col>
                        </Row>
                    </div>}
            </section>
            {expandedMoreFeature && (!isEmpty(incentiveBuyDetailData?.buy?.driver_assist) || !isEmpty(incentiveBuyDetailData?.buy?.active_safety) || !isEmpty(incentiveBuyDetailData?.buy?.self_driving) || !isEmpty(chargingDetail?.bi_directional_charging)) && (
                <Fragment>
                    <section className="mx-lg-5 mt-3 mx-3 more-info-2">
                        <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                            <div className="d-flex more-info">
                                {(!isEmpty(incentiveBuyDetailData?.buy?.driver_assist || !isEmpty(incentiveBuyDetailData?.buy?.active_safety))) && (
                                    <div className="d-flex flex-nowrap">
                                        <div className="me-4">
                                            <h6 className="font-midium font-w font-black">Safety & Assist</h6>
                                            <ul className="mt-3">
                                                {!isEmpty(incentiveBuyDetailData?.buy?.driver_assist) && (incentiveBuyDetailData?.buy?.driver_assist.split(",")?.map(
                                                    (safetyVal, safetyKey) => {
                                                        return (
                                                            <li className="font-midium font-w4 font-color" key={safetyKey}>{safetyVal?.trim()}</li>
                                                        )
                                                    }))
                                                }
                                                {!isEmpty(incentiveBuyDetailData?.buy?.active_safety) && (
                                                    incentiveBuyDetailData?.buy?.active_safety.split(",")?.map(
                                                        (safetyVal, safetyKey) => {
                                                            return (
                                                                <li className="font-midium font-w4 font-color" key={safetyKey}>{safetyVal.trim()}</li>
                                                            )
                                                        })
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {/* {!isEmpty(incentiveBuyDetailData?.buy?.active_safety) && (
                                    <div className="d-flex flex-nowrap">
                                        <div className="me-4">
                                            {isEmpty(incentiveBuyDetailData?.buy?.driver_assist) ?
                                                <h6 className="font-midium font-w font-black">Safety & Assist Features</h6>
                                                :
                                                <h6 className="font-midium font-w font-black">&nbsp;</h6>}
                                            <ul className="mt-3">
                                                {incentiveBuyDetailData?.buy?.active_safety.split(",")?.map(
                                                    (safetyVal, safetyKey) => {
                                                        return (
                                                            <li className="font-midium font-w4 font-color" key={safetyKey}>{safetyVal.trim()}</li>
                                                        )
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                )} */}
                                {!isEmpty(incentiveBuyDetailData?.buy?.self_driving) && (
                                    <div className="d-flex flex-nowrap">
                                        <div className="me-4">
                                            <h6 className="font-midium font-w Safety Features">Self-Driving Features</h6>
                                            <ul className="mt-3">
                                                {incentiveBuyDetailData?.buy?.self_driving.split(",")?.map(
                                                    (selfDrivingsafetyVal, selfDrivingKey) => {
                                                        return (
                                                            <li className="font-midium font-w4 font-color" key={selfDrivingKey}>{selfDrivingsafetyVal.trim()}</li>
                                                        )
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {!isEmpty(chargingDetail?.bi_directional_charging) &&
                                    <div className="d-flex flex-nowrap ms-5">
                                        <div className="me-4">
                                            <h6 className="font-midium font-w Safety Features">Bi-Directional Charging</h6>
                                            <div className="mt-3 d-flex flex-column Bi-Directional">
                                                {chargingDetail?.bi_directional_charging?.split(',')?.map((val, k) => {
                                                    return (
                                                        <Fragment>
                                                            {/* <li className="font-midium font-w4 font-color" key={k}>{val.trim()}</li> */}
                                                            <img className="pb-3" src={process.env.REACT_APP_BI_CHARGING_BASE_URL + val + ".svg"} alt="" key={k} />
                                                        </Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </Fragment>
            )}
        </Fragment>
    );
}

export default withRouter(ModelDetailSection);