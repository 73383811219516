import axios from 'axios';
export const getLocalStorageData = (key) => {
    return localStorage.getItem(key);
}

export const setLocalStorageData = (key, values) => {
    localStorage.setItem(key, values);
}

export const removeLocalStorageData = (key) => {
    return localStorage.removeItem(key);
}


const sesstionKeyname = "user_data";
const zipcodeKeyname = "currentZipcode";


export const getLoginUserData = () => {
    let dd = getLocalStorageData(sesstionKeyname) || null;

    if (dd === null || dd === undefined || dd === "") {
        return null;
    } else {

        try {
            dd = JSON.parse(dd);
        }
        catch (err) {
            console.log("json parse error ");
            dd = null;
        }

        return dd;
    }
}

export const setLoginUserData = (data) => {
    setLocalStorageData(sesstionKeyname, JSON.stringify(data));
}

export const clearLoginUserData = () => {
    removeLocalStorageData(sesstionKeyname);
}

export const setUserCurrentZipCode = async () => {
    if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(async position => {
                const { latitude, longitude } = position.coords;
                try {
                    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                    const data = response.data;
                    if (data.results[0]) {
                        const addressComponents = data.results[0].address_components;
                        const zip = addressComponents.find(component => component.types.includes('postal_code'));
                        if (zip) {
                            localStorage.setItem("currentZipcode", zip.long_name);
                            resolve(zip.long_name);
                        } else { reject("Zip code not found"); }
                    } else {
                        reject("No results found");
                    }
                } catch (error) {
                    reject(error);
                }
            }, error => { console.error('Error getting location', error); reject(error); });
        });
    } else {
        console.error('Geolocation is not supported by this browser.'); throw new Error('Geolocation is not supported by this browser.');
    }
}

export const getUserCurrentZipCode = () => {
    if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(async position => {
                const { latitude, longitude } = position.coords;
                try {
                    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
                    const data = response.data;
                    if (data.results[0]) {
                        const addressComponents = data.results[0].address_components;
                        const zip = addressComponents.find(component => component.types.includes('postal_code'));
                        if (zip) {
                            // localStorage.setItem("currentZipcode", zip.long_name);
                            resolve(zip.long_name);
                        } else { reject("Zip code not found"); }
                    } else {
                        reject("No results found");
                    }
                } catch (error) {
                    reject(error);
                }
            }, error => { console.error('Error getting location', error); reject(error); });
        });
    } else {
        console.error('Geolocation is not supported by this browser.'); throw new Error('Geolocation is not supported by this browser.');
    }
}