import { Header } from "./Header";
import Footer from "./Footer";
import { AuthPopUps } from "../components/AuthPopUps/index";
import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";

const Layout = ({ children, ...rest }) => {

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.AuthPopupState,
    }));

    const dispatch = useDispatch();

    setTimeout(() => {
        document.querySelectorAll('.replace-hyphen').forEach(element => {
            element.innerHTML = element.innerHTML.replace(/-/g, '&#8209;');
        });
    }, 100);

    return (
        <React.Fragment>

            <Header />

            {children}

            <Footer />
            
            < AuthPopUps />

        </React.Fragment>
    )
}

export default Layout;