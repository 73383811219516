import React, { useState, useMemo, useEffect } from "react";
import * as apiHelper from "../../helper/api_helper";
import { defaultValueIncentiveFinderFilters } from "../../components/EVIncentiveFinderFilters";
import { useSelector, useDispatch } from "react-redux";
// import { IncentiveFinderByVehicle, setIncentiveFinderByVehicleLoading, updateIncentiveFinderByVehicle } from "../../store/action";
import { setEVIncentiveFinderLoading, updateEVIncentiveFinder } from "../../store/action";
// import styled from "styled-components";
import { CovertEVIncentiveFinderData, ArrayAsc, isEmpty } from "../../helper/project_helper";

import DesktopView from './DesktopView';
import MobileView from './MobileView';
import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useMyHistory } from "../../components/hooks/useMyHistory";
import PageLoaderComponent from "../../components/PageLoaderComponent";

const EVIncentiveFinder = ({ props }) => {

	const { stateEVIncentiveFinderFilters, stateIncentives } = useSelector((state) => ({
		stateEVIncentiveFinderFilters: state.EVIncentiveFinderFilters,
		stateIncentives: state.EVIncentiveFinder,
	}));
	// const [dispBlank, setdispBlank] = useState(true);
	const [first, setFirstLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const [loading, setLoading] = useState(false);
	const [filterState, setFilterState] = useState(stateIncentives?.filter || defaultValueIncentiveFinderFilters());
	const [filter, setFilter] = useState(stateIncentives?.filter || defaultValueIncentiveFinderFilters());
	const [expandedRows, setExpandedRows] = useState(stateIncentives?.expandedRows || {});
	const [compareVehicles, setCompareVehicles] = useState([]);
	const [compareVehiclesChecked, setCompareVehiclesChecked] = useState([]);
	const [compareVehiclesError, setCompareVehiclesError] = useState(false);
	const [changeReset, setchangeReset] = useState(false);
	const [lastUpdatedDate, setLastUpdatedDate] = useState(stateIncentives?.last_updated_date || false);

	const onClose = React.useCallback(() => {

	}, []);

	// useEffect(() => {
	// 	if (firstLoad === true) {
	// 		setTimeout(() => {
	// 			window.scrollTo(0, 0); // Add a small delay
	// 			setFirstLoad(false);
	// 		}, 100);
	// 	}
	// })
	const dispatch = useDispatch();
	const history = useMyHistory();


	// const { stateEVIncentiveFinderFilters, stateIncentives } = useSelector((state) => ({
	// 	stateEVIncentiveFinderFilters: state.EVIncentiveFinderFilters,
	// 	stateIncentives: state.EVIncentiveFinder,
	// }));

	const tooltipText = useMemo(() => {
		if (stateEVIncentiveFinderFilters?.data !== false) {
			if (stateEVIncentiveFinderFilters?.data?.tooltips !== null) {
				return stateEVIncentiveFinderFilters?.data?.tooltips;
			}
		}
		return null;
	}, [stateEVIncentiveFinderFilters]);

	const page_title = useMemo(() => {
		if (stateEVIncentiveFinderFilters?.data !== false) {
			if (stateEVIncentiveFinderFilters?.data?.page_title !== null) {
				return stateEVIncentiveFinderFilters?.data?.page_title;
			}
		}
		return null;
	}, [stateEVIncentiveFinderFilters]);

	const page_meta_description = useMemo(() => {
		if (stateEVIncentiveFinderFilters?.data !== false) {
			if (stateEVIncentiveFinderFilters?.data?.page_meta_description !== null) {
				return stateEVIncentiveFinderFilters?.data?.page_meta_description;
			}
		}
		return null;
	}, [stateEVIncentiveFinderFilters]);



	useEffect(() => {
		if (!isEmpty(page_title)) {
			document.title = page_title;
		}
		if (!isEmpty(page_meta_description)) {
			document.querySelector('meta[name="description"]').setAttribute("content", page_meta_description);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page_title, page_meta_description]);


	useEffect(() => {
		dispatch(updateEVIncentiveFinder({
			expandedRows: expandedRows
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expandedRows]);

	useEffect(() => {

		if (stateIncentives.loading === false && first === false && stateIncentives.error === '') {

			if (stateIncentives?.filter !== undefined && stateIncentives?.filter !== false) {
				const lastFilter = ArrayAsc(stateIncentives?.filter);
				const currentFilter = ArrayAsc(filter);

				if (JSON.stringify(lastFilter) !== JSON.stringify(currentFilter)) {
					setLoading(false);
					getIncentives(filter);
					setNotFound(true);
				}
			} else {
				setLoading(false);
				getIncentives(filter);
				setNotFound(true);
			}
			// setdispBlank(false);
		}
		setFirstLoading(false);


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, changeReset]);

	const expandAllRows = (filter, table_data_array) => {
		if (filter !== null && filter !== false && filter !== undefined) {

			if (!isEmpty(filter?.make) || !isEmpty(filter?.model) || !isEmpty(filter?.model_year) || !isEmpty(filter?.base_msrp) || !isEmpty(filter?.segments) || !isEmpty(filter?.base_msrp) || filter?.potential_savings === true || filter?.eligible === true) {
				let exp = {};
				table_data_array.forEach(async (item_val, item_key) => {
					if (!("maker" in item_val)) {
						const rowId = "hidden_model_row" + item_key;
						const currentExp = { ...exp };
						exp = { ...currentExp, [rowId]: true }
					}
				})
				setExpandedRows(exp);

			}
		}
	};
	
	const incentives = useMemo(() => {
		if (stateIncentives.data !== false) {
			return stateIncentives.data;
		}
		return null;
	}, [stateIncentives]);

	async function getIncentives(params = {}) {

		setLoading(true);

		dispatch(setEVIncentiveFinderLoading(true))
		try {
			const response = await apiHelper.postEVIncentiveFinder(params);
			var table_data_array = [];
			if (response.data.status === 1) {
				// table_data_array = CovertEVIncentiveFinderData(response?.data?.body);
				table_data_array = CovertEVIncentiveFinderData(response?.data?.body?.data);
				dispatch(updateEVIncentiveFinder({
					loading: false,
					status: true,
					data: table_data_array,
					filter: params,
					last_updated_date: response?.data?.body?.last_updated_date
				}));

				setLastUpdatedDate(response?.data?.body?.last_updated_date);

				setTimeout(() => {
					expandAllRows(params, table_data_array);
					if (table_data_array.length === 0) {
						document.getElementById('scroll_here').scrollIntoView({ behavior: 'smooth', block: 'start' });
					}
				}, 100)

			} else {
				dispatch(updateEVIncentiveFinder({
					loading: false,
					status: true,
					error: response?.data,
					expandedRows: null
				}));
			}


		} catch (error) {
			console.error(error);
			dispatch(updateEVIncentiveFinder({
				loading: false,
				status: false,
				error: error,
				expandedRows: null
			}));
		}
		setLoading(false);
		// setdispBlank(false);
	}

	const getCompareValue = (key) => {
		let cc = compareVehiclesChecked?.filter(x => x.vehicle_id === key);
		if (cc?.length > 0) {
			return cc[0].checked || false;
		}
		return false;
	}

	const handleCompareCheck = (compareData) => {
		if (compareData === false) {
			setCompareVehicles([]);
			setCompareVehiclesChecked([]);
		} else {
			setCompareVehiclesError(false);
			const { value, checked } = compareData.target;
			const compareDataArray = value?.split('|');
			const exists = compareVehicles.some(v => v.vehicle_id === compareDataArray[0]);

			let allData = [...compareVehiclesChecked];

			let cc = allData.filter(x => x.vehicle_id === Number(compareDataArray[0]));
			if (cc.length > 0) {
				let ext = cc[0].checked || false;
				cc[0].checked = !ext;
			} else {
				allData.push({
					vehicle_id: Number(compareDataArray[0]),
					checked: true,
				});
			}

			if (checked) {
				if (!exists) {
					const pushOrRemoveObj = {
						vehicle_id: compareDataArray[0],
						make: compareDataArray[1],
						model: compareDataArray[2],
						model_year: compareDataArray[3],
						trim: compareDataArray[4],
					}
					if ([...compareVehicles, pushOrRemoveObj].length > 3) {
						setCompareVehiclesError("Select up to 3 vehicles.")
						setTimeout(() => {
							setCompareVehiclesError(false);
						}, 5000);
					} else {
						setCompareVehicles([...compareVehicles, pushOrRemoveObj]);
						setCompareVehiclesChecked(allData);
					}
				}
			} else {
				if (exists) {
					setCompareVehicles(compareVehicles.filter(v => v.vehicle_id !== compareDataArray[0]));
					setCompareVehiclesChecked(allData);
				}
			}
		}
	}

	const onClickCompareVehicle = () => {
		if (compareVehicles?.length < 2) {
			setCompareVehiclesError("Select at least 2 vehicles.");
			setTimeout(() => {
				setCompareVehiclesError(false);
			}, 5000);
			return false;
		}
		const compare_data = JSON.stringify(compareVehicles.map(({ vehicle_id, ...rest }) => ({
			...rest
		})));
		history.push("/comparevehicles/" + compare_data, true);
	};


	return (
		<Fragment>
			<PageLoaderComponent loading={stateIncentives?.loading} />
			{isEmpty(stateIncentives?.error?.msg) && isEmpty(stateEVIncentiveFinderFilters?.error?.msg) &&
				<Fragment>
					<DesktopView
						reset={(dd) => {
							setCompareVehicles([]);
							setCompareVehiclesChecked([]);
							dispatch(updateEVIncentiveFinder({
								loading: false,
								status: false,
								data: null,
								expandedRows: null
							}));
							setFilter(dd);
							setFilterState(dd);
							setExpandedRows({});
							setchangeReset(!changeReset);
							getIncentives(dd);
							setTimeout(() => {
								window.scrollTo(0, 0); // Add a small delay
							}, 100);
						}}
						setFilter={setFilter}
						setFilterState={setFilterState}
						setFirstLoading={setFirstLoading}
						setExpandedRows={setExpandedRows}
						setNotFound={setNotFound}
						incentives={incentives}
						loading={loading}
						notFound={notFound}
						filterState={filterState}
						expandedRows={expandedRows}
						filter={filter}
						onClickCompareVehicle={onClickCompareVehicle}
						handleCompareCheck={handleCompareCheck}
						compareVehicles={compareVehicles}
						compareVehiclesError={compareVehiclesError}
						//new state cehckbox 
						valueCompareCheck={compareVehiclesChecked}
						onChangeCompareCheck={setCompareVehiclesChecked}
						getCompareValue={getCompareValue}
						tooltipText={tooltipText}
						lastUpdatedDate={lastUpdatedDate}
					/>

					<MobileView
						reset={(dd) => {
							if (JSON.stringify(ArrayAsc(filter)) !== JSON.stringify(ArrayAsc(dd))) {
								dispatch(updateEVIncentiveFinder({
									loading: false,
									status: false,
									data: null,
									expandedRows: null
								}));
								setFilter(dd);
								setFilterState(dd);
								setExpandedRows({});
								setchangeReset(!changeReset);
								setCompareVehicles([]);
								setCompareVehiclesChecked([]);
								getIncentives(dd);
								setTimeout(() => {
									window.scrollTo(0, 0); // Add a small delay
								}, 100);
							}
						}}
						setFilter={setFilter}
						setFilterState={setFilterState}
						setFirstLoading={setFirstLoading}
						setExpandedRows={setExpandedRows}
						setNotFound={setNotFound}
						incentives={incentives}
						loading={loading}
						notFound={notFound}
						filterState={filterState}
						expandedRows={expandedRows}
						filter={filter}
						onClose={onClose}
						handleCompareCheck={handleCompareCheck}
						compareVehicles={compareVehicles}
						getCompareValue={getCompareValue}
						compareVehiclesError={compareVehiclesError}
						onClickCompareVehicle={onClickCompareVehicle}
						tooltipText={tooltipText}
						lastUpdatedDate={lastUpdatedDate}
					/>
				</Fragment>}
		</Fragment>
	);
}

export default withRouter(EVIncentiveFinder);