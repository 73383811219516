// import React from "react";
// import { Redirect } from "react-router-dom";
import EVIncentiveFinder from "../pages/EVIncentiveFinder";
import EVIncentiveDetail from "../pages/EVIncentiveDetail";
import IncentiveDetailSearch from "../pages/IncentiveDetailSearch";
import CompareVehicles from "../pages/CompareVehicles";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ContactUs from "../pages/ContactUs";
import Account from "../pages/Account";
import Basic404 from "../pages/Basic404";
import Unauthorized from "../pages/Unauthorized";
const authProtectedRoutes = []

const publicRoutes = [
    { path: "/404", component: Basic404 },
    { path: "/unauthorized", component: Unauthorized },
    { path: "/detail/:purchase_type/:vehicle_id/:make/:model/:model_year/:trim?/", component: EVIncentiveDetail },
    { path: "/incentivedetail/:purchase_type?/:vehicle_id?/:make?/:model?/:model_year?/:trim?", component: IncentiveDetailSearch },
    { path: "/comparevehicles/:compare_vehicles", component: CompareVehicles },
    { path: "/privacypolicy", component: PrivacyPolicy },
    { path: "/contact", component: ContactUs },
    { path: "/account", component: Account },
    {
        path: "/",
        exact: true,
        component: EVIncentiveFinder,
    },
    // {
    //     path: "/",
    //     exact: true,
    //     component: IncentiveFinderByVehicle,
    // },
];

export { authProtectedRoutes, publicRoutes };
