import React, { Fragment } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faUpRightFromSquare,
    faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import Mobile from "../../components/IncentiveDetailSearchFilter/Mobile";
import { NumberWithThousandSeparator, DateFormatterExpirationDate, DateFormatterLastUpdated, isEmpty, createSeoFriendlyUrl } from "../../helper/project_helper";
import CheckGreen from "../../components/WebComponent/CheckGreen";
import RedClose from "../../components/WebComponent/RedClose";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { withRouter, Link } from "react-router-dom";
import { AuthButton } from "../../components/AuthButton";

const IncentiveDetailSearchMobile = ({
    expandedRows,
    handleHideShowClick,
    filterList,
    onChangeFilter,
    value,
    onSearchClick,
    onResetClick,
    handleZipBlur,
    zipCodeError,
    incentiveData,
    tooltipText,
    blankFilterError,
    history,
    handleShow,
    handleClose,
    show,
    filterloading
}) => {



    let vehicle_data = incentiveData?.vehicle_data;
    let fed_incentive = incentiveData?.fed_incentive;
    let eligibility_object = incentiveData?.fed_incentive?.eligibility_object;
    let electric_incentives = incentiveData?.electric_incentives;
    let charging_incentives = incentiveData?.charging_incentives;

    return (
        <Fragment >
            <main className="mobile-view" style={{ overflowX: "clip" }}>
                {/* <section>
                    <div className="insentive-detaile-search">
                        <div className="text-p-detaile2">
                            <h1 className=" pb-3 font-px font-w font-white">Incentive Detail Finder</h1>
                            {vehicle_data &&
                                <div>
                                    <p className="font-midium font-w pb-3 font-white">{vehicle_data?.model_year} {vehicle_data?.make} {vehicle_data?.model} {vehicle_data?.trim}</p>
                                    <p className="font-small font-white font-wxl">Incentive Details</p>
                                </div>}
                        </div>
                    </div>
                </section> */}
                <section className="">
                    <div className="bg-incentive-detail-top">
                        <div
                            className="text-p-detaile-new"
                            style={{ alignContent: vehicle_data ? "left" : "center" }}
                        >
                            <h1 className="font-white font-wx m-0 idf-title" style={{ fontSize: "56px", paddingBottom: vehicle_data ? "24px" : "0" }}>
                                Incentive Detail Finder
                            </h1>
                            {vehicle_data && (
                                <Fragment>
                                    <p className="font-white font-largexl font-w idf-p" style={{ paddingBottom: "16px" }}>
                                        {vehicle_data?.model_year} {vehicle_data?.make} {vehicle_data?.model} {vehicle_data?.trim}
                                    </p>
                                    <p className="font-white font-large idf-text">Incentive Details</p>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </section>
                <section className="insentive-main-layout">
                    <Row>
                        <Mobile
                            handleClose={handleClose}
                            show={show}
                            filterList={filterList}
                            onChangeFilter={onChangeFilter}
                            value={value}
                            onSearchClick={onSearchClick}
                            onResetClick={onResetClick}
                            handleZipBlur={handleZipBlur}
                            zipCodeError={zipCodeError}
                            blankFilterError={blankFilterError}
                            filterloading={filterloading}
                        />
                        <Col lg={12}>
                            <div>
                                <div className="mx-3">
                                    {(vehicle_data?.model_year && vehicle_data?.make && vehicle_data?.model) ? (
                                        <div className="pb-4 pt-2 font-midium font-wxl font-black">
                                            View more information about the vehicle and check if you are eligible for Federal Tax Credit on&nbsp;
                                            <div style={{ display: "flex" }}>
                                                <Link className="text-decoration-underline font-midium font-wxl font-black" to={"/detail/" + vehicle_data?.purchase_type?.toLowerCase + "/" + vehicle_data?.vehicle_id + "/" + createSeoFriendlyUrl(vehicle_data?.make) + "/" + createSeoFriendlyUrl(vehicle_data?.model) + "/" + vehicle_data?.model_year + "/" + createSeoFriendlyUrl(vehicle_data?.trim)}
                                                ><span style={{ whiteSpace: "nowrap" }}>Vehicle Detail Page</span></Link>
                                                <span className="small-space">.</span></div>
                                        </div>
                                    ) : (
                                        <p className="pb-4 pt-2 font-midium font-wxl font-black">
                                            Use the filters to view complete incentive details for the vehicle of your choice.
                                        </p>
                                    )}
                                    <div className="d-flex justify-content-between align-items-center">

                                        <Button className="btn-learn-more size-change" onClick={handleShow}>Filters</Button>
                                        {/* <button className="text-decoration-none button-set">
                                            <span className="me-2 text-dark font-small font-wxl font-black">Share</span>
                                            <img width={16} src={share} alt="" />
                                        </button> */}
                                    </div>
                                </div>
                                <h5 className="my-4 font-w font-color search-datile font-midium py-2 px-3">Vehicle Incentives</h5>

                                {fed_incentive &&
                                    <Fragment>
                                        <div className="d-flex align-items-center justify-content-between detile-top px-3" style={{ background: "#fff" }} >
                                            <span className="py-3 font-w width-set font-large font-color replace-hyphen">{fed_incentive?.incentive_name}</span>
                                            <span className="py-3 font-midium saving-set font-wxl font-color text-center">{fed_incentive?.fed_tax_credit_amount ? "$" + NumberWithThousandSeparator(fed_incentive?.fed_tax_credit_amount) : "$0"}</span>
                                            <div className="py-3">
                                                {expandedRows["hidden_row_0"] ? (
                                                    <FontAwesomeIcon
                                                        icon={faChevronUp}
                                                        onClick={() =>
                                                            handleHideShowClick(
                                                                "hidden_row_0"
                                                            )
                                                        }
                                                        className="cursor-pointer"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faChevronDown}
                                                        onClick={() =>
                                                            handleHideShowClick(
                                                                "hidden_row_0"
                                                            )
                                                        }
                                                        className="cursor-pointer"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {expandedRows["hidden_row_0"] && fed_incentive &&
                                            (<div>
                                                <div className="py-3" style={{ background: "#fff" }}>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <div className="text-center">
                                                                <h5 className="font-midium font-w font-black">{fed_incentive?.provider_name}</h5>
                                                                <p className="font-smallx font-w4 font-black">Provider</p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6}>
                                                            {!isEmpty(fed_incentive?.end_date) &&
                                                                <div className="text-center">
                                                                    <h5 className="font-midium font-w font-black">{DateFormatterExpirationDate(fed_incentive?.end_date)}</h5>
                                                                    <p className="font-smallx font-w4 font-black">Exp. Date</p>
                                                                </div>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="p-3" style={{ background: "#fff" }}>
                                                    <Row>
                                                        {eligibility_object &&
                                                            <Fragment>
                                                                <Col lg={6} xs={6}>
                                                                    {eligibility_object?.map((eligibilityCategoryObjectVal, eligibilityCategoryObjectKey) => {
                                                                        return (
                                                                            eligibilityCategoryObjectKey < 3 &&
                                                                            <div className="mb-3" key={eligibilityCategoryObjectKey}>
                                                                                <span className="font-midium font-w font-black">{eligibilityCategoryObjectVal?.category_name}</span>
                                                                                {eligibilityCategoryObjectVal?.attr &&
                                                                                    eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                                        return (
                                                                                            <div className="d-flex" key={eligibilityReqObjectKey}>
                                                                                                {eligibilityReqObjectVal?.valid ?
                                                                                                    <i className="me-2">
                                                                                                        <CheckGreen />
                                                                                                    </i> :
                                                                                                    <i className="me-2">
                                                                                                        <RedClose />
                                                                                                    </i>
                                                                                                }<span className="font-small font-wxl font-black">{eligibilityReqObjectVal?.requirement}</span>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Col>
                                                                <Col lg={6} xs={6}>
                                                                    {eligibility_object?.map((eligibilityCategoryObjectVal, eligibilityCategoryObjectKey) => {
                                                                        return (
                                                                            eligibilityCategoryObjectKey > 2 &&
                                                                            <div className="mb-3" key={eligibilityCategoryObjectKey}>
                                                                                <span className="font-midium font-w">{eligibilityCategoryObjectVal?.category_name}</span>
                                                                                {eligibilityCategoryObjectVal?.attr &&
                                                                                    eligibilityCategoryObjectVal?.attr?.map((eligibilityReqObjectVal, eligibilityReqObjectKey) => {
                                                                                        return (
                                                                                            <div className="d-flex" key={eligibilityReqObjectKey}>
                                                                                                {eligibilityReqObjectVal?.valid ?
                                                                                                    <i className="me-2">
                                                                                                        <CheckGreen />
                                                                                                    </i> :
                                                                                                    <i className="me-2">
                                                                                                        <RedClose />
                                                                                                    </i>
                                                                                                }<span className="font-small font-wxl">{eligibilityReqObjectVal?.requirement}</span>
                                                                                                {(((eligibilityReqObjectVal?.eligibility_requirement_rule === "Critical Minerals Requirement" && !isEmpty(tooltipText[5])) || (eligibilityReqObjectVal?.eligibility_requirement_rule === "Battery Components Requirement" && !isEmpty(tooltipText[6])))) &&
                                                                                                    <OverlayTrigger
                                                                                                        placement="bottom"
                                                                                                        trigger={['hover', 'click']}
                                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                                        overlay={
                                                                                                            <Tooltip className="custom-tooltip ">
                                                                                                                <div className="tc-text">
                                                                                                                    {eligibilityReqObjectVal?.eligibility_requirement_rule === "Critical Minerals Requirement" ?
                                                                                                                        tooltipText[5]
                                                                                                                        : eligibilityReqObjectVal?.eligibility_requirement_rule === "Battery Components Requirement" ?
                                                                                                                            tooltipText[6]
                                                                                                                            : ""}
                                                                                                                </div>
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                    >
                                                                                                        <svg className="ms-1 mt-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16"><g fill="#21272A"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" /><path d="m8.93 6.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0" /></g></svg>
                                                                                                    </OverlayTrigger>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Col>
                                                            </Fragment>}
                                                    </Row>
                                                    <div className="pt-3">
                                                        <p className="font-small font-wxl font-black"><span className="font-w">Combinable?</span>
                                                            {tooltipText?.[4] &&
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    trigger={['hover', 'click']}
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={
                                                                        <Tooltip className="custom-tooltip ">
                                                                            <div className="tc-text">
                                                                                <span className="font-white font-large font-w4">{tooltipText?.[4]}</span>
                                                                            </div>
                                                                        </Tooltip>
                                                                    }>
                                                                    <svg className="ms-1 me-2 mb-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16"><g fill="#21272A"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" /><path d="m8.93 6.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0" /></g></svg>
                                                                </OverlayTrigger>
                                                            }
                                                            {fed_incentive?.is_combinable ? "Yes" : "No"}
                                                        </p>
                                                        {!isEmpty(fed_incentive?.redemption_text) &&
                                                            <p className="font-small font-wxl font-black mt-3"><span className="font-w">How to Claim?</span> {fed_incentive?.redemption_text}</p>}
                                                        {/* {fed_incentive?.redemption_link &&
                                                            <a href={fed_incentive?.redemption_link} target="_blank" rel="noopener noreferrer" className="btn-learn-more text-center mt-3 mb-5">Learn more<FontAwesomeIcon width={12} height={12} className="ms-2" icon={faUpRightFromSquare} /></a>
                                                        } */}
                                                        {!isEmpty(fed_incentive?.incentive_link) &&
                                                            <Fragment>
                                                                <a className="btn-learn-more text-center d-none" id='fed_mb_link' target="_blank" href={fed_incentive?.incentive_link} rel="noopener noreferrer" >
                                                                </a>
                                                                <AuthButton className="btn-learn-more text-center mt-3" onLogin={(s) => {
                                                                    const button = document.getElementById("fed_mb_link");
                                                                    button.click();
                                                                }}>
                                                                    Learn more<FontAwesomeIcon width={12} height={12} className="ms-2" icon={faUpRightFromSquare} /></AuthButton></Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>)}
                                    </Fragment>
                                }
                                {electric_incentives?.map((object, i) => {
                                    return (
                                        <Fragment key={"elecrtic_" + i}>
                                            <div className="d-flex align-items-center justify-content-between detile-top px-3" style={{ background: "#fff" }} >
                                                <span className="py-3 font-w width-set font-large replace-hyphen">{object?.incentive_name}</span>
                                                <span className="py-3 font-midium saving-set font-wxl text-center replace-hyphen">{object?.incentive_amount}</span>
                                                <div className="py-3">
                                                    {expandedRows["hidden_row_" + object?.incentive_id] ? (
                                                        <FontAwesomeIcon
                                                            icon={faChevronUp}
                                                            onClick={() =>
                                                                handleHideShowClick(
                                                                    "hidden_row_" + object?.incentive_id
                                                                )
                                                            }
                                                            className="cursor-pointer"
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                            onClick={() =>
                                                                handleHideShowClick(
                                                                    "hidden_row_" + object?.incentive_id
                                                                )
                                                            }
                                                            className="cursor-pointer"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {expandedRows["hidden_row_" + object?.incentive_id] &&
                                                (
                                                    <div className="p-3 border-bottom">
                                                        <div className="py-3" style={{ background: "#fff" }}>
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <div className="text-center">
                                                                        <h5 className="font-midium font-w font-black">{object?.provider_name}</h5>
                                                                        <p className="font-smallx font-w4 font-black">Provider</p>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    {!isEmpty(object?.end_date) &&
                                                                        <div className="text-center">
                                                                            <h5 className="font-midium font-w font-black">{DateFormatterExpirationDate(object?.end_date)}</h5>
                                                                            <p className="font-smallx font-w4 font-black">Exp. Date</p>
                                                                        </div>}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {/* <p className="font-midium font-w4 font-black mb-2">Details & Restrictions:</p> */}

                                                        <div className="pt-3">

                                                            {!isEmpty(object?.eligibility_requirement_text) &&
                                                                <p className="font-small font-w4 font-black mt-3"><span className="font-w">Eligibility Requirements:</span> {object?.eligibility_requirement_text}</p>}

                                                            {!isEmpty(object?.include_state) &&
                                                                <p className="font-small font-wxl font-black mt-3"><span className="font-w me-1">Available in:</span> {object?.include_state.replace(/,/g, ', ')}</p>}

                                                            {!isEmpty(object?.exclude_state) &&
                                                                <p className="font-small font-wxl font-black mt-3"><span className="font-w me-1">exclude_state</span> {object?.exclude_state.replace(/,/g, ', ')}</p>}

                                                            <p className="font-small font-wxl font-black mt-3"><span className="font-w me-1">Combinable?</span>
                                                                {tooltipText?.[4] &&
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={
                                                                            <Tooltip className="custom-tooltip ">
                                                                                <div className="tc-text">
                                                                                    <span className="font-white font-large font-w4">{tooltipText?.[4]}</span>
                                                                                </div>
                                                                            </Tooltip>
                                                                        }>
                                                                        <svg className="ms-1 me-2 mb-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16"><g fill="#21272A"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" /><path d="m8.93 6.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0" /></g></svg>
                                                                    </OverlayTrigger>
                                                                }
                                                                {object?.is_combinable ? "Yes" : "No"}
                                                            </p>
                                                            {!isEmpty(object?.exclusion_text) &&
                                                                <p className="font-small font-wxl font-black"><span className="font-w me-1">Exclusions:</span> {object?.exclusion_text}</p>}
                                                            {!isEmpty(object?.redemption_text) &&
                                                                <p className="font-small font-w4 font-black mt-3"><span className="font-w">How to Claim?</span> {object?.redemption_text}</p>}
                                                            {/* {!isEmpty(object?.redemption_link) &&
                                                                <a href={object?.redemption_link} target="_blank" rel="noopener noreferrer" className="btn-learn-more text-center mt-2">Learn more<FontAwesomeIcon width={12} height={12} className="ms-2" icon={faUpRightFromSquare} /></a>
                                                            } */}
                                                            {!isEmpty(object?.incentive_link) &&
                                                                <Fragment>
                                                                    <a className="btn-learn-more text-center d-none" id='el_in_mb_link' target="_blank" href={object?.incentive_link} rel="noopener noreferrer" >
                                                                    </a>
                                                                    <AuthButton className="btn-learn-more text-center mt-3" onLogin={(s) => {
                                                                        const button = document.getElementById("el_in_mb_link");
                                                                        button.click();
                                                                    }}>
                                                                        Learn more<FontAwesomeIcon width={12} height={12} className="ms-2" icon={faUpRightFromSquare} /></AuthButton>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                        </Fragment>
                                    )
                                })}
                                <div className="pb-5" style={{ background: "#fff" }}></div>
                            </div>


                            <h5 className="my-4 font-w font-px search-datile font-w font-color font-midium py-2 px-3">Charging Incentives</h5>

                            {charging_incentives?.map(
                                (object, i) => {
                                    return (
                                        <Fragment key={"charging_" + i}>
                                            <div className="d-flex align-items-center justify-content-between detile-top px-3" style={{ background: "#fff" }} >
                                                <span className="py-3 font-w width-set font-large replace-hyphen">{object?.incentive_name}</span>
                                                <span className="py-3 font-midium text-center saving-set font-wxl text-center replace-hyphen">{object?.incentive_amount}</span>
                                                <div className="py-3">
                                                    {expandedRows["hidden_row_" + object?.incentive_id] ? (
                                                        <FontAwesomeIcon
                                                            icon={faChevronUp}
                                                            onClick={() =>
                                                                handleHideShowClick(
                                                                    "hidden_row_" + object?.incentive_id
                                                                )
                                                            }
                                                            className="cursor-pointer"
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                            onClick={() =>
                                                                handleHideShowClick(
                                                                    "hidden_row_" + object?.incentive_id
                                                                )
                                                            }
                                                            className="cursor-pointer"
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                            {expandedRows["hidden_row_" + object?.incentive_id] &&
                                                (
                                                    <div className="p-3">
                                                        <div className="py-3" style={{ background: "#fff" }}>
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <div className="text-center">
                                                                        <h5 className="font-midium font-w font-black">{object?.provider_name}</h5>
                                                                        <p className="font-smallx font-w4 font-black">Provider</p>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    {!isEmpty(object?.end_date) &&
                                                                        <div className="text-center">
                                                                            <h5 className="font-midium font-w font-black">{DateFormatterExpirationDate(object?.end_date)}</h5>
                                                                            <p className="font-smallx font-w4 font-black">Exp. Date</p>
                                                                        </div>}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {!isEmpty(object?.eligibility_requirement_text) &&
                                                            <p className="font-small font-w4 font-black mt-3"><span className="font-w">Eligibility Requirements:</span> {object?.eligibility_requirement_text}</p>}

                                                        {!isEmpty(object?.include_state) &&
                                                            <p className="font-small font-wxl font-black mt-3"><span className="font-w me-1">Available in:</span> {object?.include_state.replace(/,/g, ', ')}</p>}

                                                        {!isEmpty(object?.exclude_state) &&
                                                            <p className="font-small font-wxl font-black mt-3"><span className="font-w me-1">exclude_state</span> {object?.exclude_state.replace(/,/g, ', ')}</p>}

                                                        <p className="font-small font-w4 font-black mt-3"><span className="font-w">Combinable?</span>
                                                            {tooltipText?.[4] &&
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={
                                                                        <Tooltip className="custom-tooltip ">
                                                                            <div className="tc-text">
                                                                                <span className="font-white font-large font-w4">{tooltipText?.[4]}</span>
                                                                            </div>
                                                                        </Tooltip>
                                                                    }>
                                                                    <svg className="ms-1 me-2 mb-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16"><g fill="#21272A"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" /><path d="m8.93 6.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0" /></g></svg>
                                                                </OverlayTrigger>
                                                            }
                                                            {object?.is_combinable ? "Yes" : "No"}

                                                        </p>
                                                        {!isEmpty(object?.exclusion_text) &&
                                                            <p className="font-midium font-w4 font-black">{object?.exclusion_text}</p>}
                                                        {!isEmpty(object?.redemption_text) &&
                                                            <p className="font-small font-w4 font-black mt-3"><span className="font-w">How to Claim?</span> {object?.redemption_text}</p>}
                                                        <p className="font-midium font-w4 font-black"></p>
                                                        {/* {!isEmpty(object?.redemption_link) &&
                                                            <a href={object?.redemption_link} target="_blank" rel="noopener noreferrer" className="btn-learn-more text-center mt-2">Learn more<FontAwesomeIcon width={12} height={12} className="ms-2" icon={faUpRightFromSquare} /></a>
                                                        } */}
                                                        {!isEmpty(object?.incentive_link) &&
                                                            <Fragment>
                                                                <a className="btn-learn-more text-center d-none" id='charging_in_mb_link' target="_blank" href={object?.incentive_link} rel="noopener noreferrer" >
                                                                </a>
                                                                <AuthButton className="btn-learn-more text-center mt-3" onLogin={(s) => {
                                                                    const button = document.getElementById("charging_in_mb_link");
                                                                    button.click();
                                                                }}>
                                                                    Learn more<FontAwesomeIcon width={12} height={12} className="ms-2" icon={faUpRightFromSquare} /></AuthButton>
                                                            </Fragment>

                                                        }
                                                    </div>
                                                )}
                                        </Fragment>)
                                }
                            )}
                            <div className="pb-5" style={{ background: "#fff" }}></div>
                            {!isEmpty(incentiveData?.last_updated_date) &&
                                <div>
                                    <label className="font-small font-w4 font-black mt-4 p-3">Last updated: {DateFormatterLastUpdated(incentiveData?.last_updated_date)}</label>
                                </div>}
                        </Col>
                    </Row>
                </section>
            </main>
        </Fragment>
    );
};

export default withRouter(IncentiveDetailSearchMobile);