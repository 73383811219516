import React, { useState, useMemo, useEffect } from "react";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { VerificationCode } from "./VerificationCode";
import { useSelector, useDispatch } from "react-redux";
import { updateAuthPoupState, updateTempUserDetails, setUserDetailsLoading } from "../../store/action";
import { defaultPopUpState, isValidateZipcode, isEmpty } from "../../helper/project_helper";
import { useAuth } from "../hooks/useAuth";
import { useLocation } from 'react-router-dom';
import { useMyHistory } from "../hooks/useMyHistory";
import * as apiHelper from "../../helper/api_helper";

export const AuthPopUps = () => {
    const location = useLocation();
    const [isLogin, userData] = useAuth();
    if(userData !== undefined)
    {
        
    }

    const { statePopUpData } = useSelector((state) => ({
        statePopUpData: state.AuthPopupState,
    }));
    const dispatch = useDispatch();
    const history = useMyHistory();

    const [authCodeErrorMsg, setAuthCodeErrorMsg] = useState(false);
    const [invalidZipCode, setInvalidZipCode] = useState(false);
    const [loginEmail, setLoginEmail] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [emailExistMessage, setEmailExistMessage] = useState(false);
    const [emailNotExistMessage, setEmailNotExistMessage] = useState(false);

    useEffect(() => {
        if (zipCode?.length !== 5) {
            setInvalidZipCode("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCode]);

    const displayPopUps = useMemo(() => {
        if (statePopUpData.SignIn === true || statePopUpData.SignUp === true || statePopUpData?.VerificationCode === true) {
            return true;
        }
        return false;
    }, [statePopUpData]);

    if (isLogin) {
        return false;
    }
    const showSignIn = async () => {
        if (authCodeErrorMsg !== false) {
            setAuthCodeErrorMsg(false);
        }
        // const valid = await validateEmail();

        // if (valid || valid === 1) {
        setEmailExistMessage(false);
        dispatch(updateAuthPoupState({ ...defaultPopUpState, SignIn: true }));
        // }
    }

    const showSignUp = async () => {
        if (authCodeErrorMsg !== false) {
            setAuthCodeErrorMsg(false);
        }
        const valid = await validateEmail();

        if (!valid || valid === 1) {
            setEmailNotExistMessage(false);
            dispatch(updateAuthPoupState({ ...defaultPopUpState, SignUp: true }));
        }
    }

    const showVerificationCode = () => {
        if (authCodeErrorMsg !== false) {
            setAuthCodeErrorMsg(false);
        }
        dispatch(updateAuthPoupState({ ...defaultPopUpState, VerificationCode: true }));
    }

    const closeAllPopUp = () => {
        setAuthCodeErrorMsg(false);
        setInvalidZipCode(false);
        dispatch(updateAuthPoupState({ ...defaultPopUpState }));
        if (location.pathname.indexOf("comparevehicles") !== 1) {
            if (location.pathname === '/' || location.pathname.indexOf("/evincentivefinder") === 1) {
                history.push("/");
            } else {
                history.push("/", true);
            }
        }
    }

    const dontWantLogin = () => {
        dispatch(updateAuthPoupState({ ...defaultPopUpState }));
        // if (location.pathname.indexOf("comparevehicles") !== 1 && location.pathname.indexOf("detail") !== 1) {
        //     if (location.pathname === '/' || location.pathname.indexOf("/evincentivefinder") === 1) {
        //         history.push("/");
        //     } else {
        //         history.push("/", true);
        //     }
        // }
    }



    async function sendAuthCode(params = {}) {

        dispatch(setUserDetailsLoading(true));
        try {
            const response = await apiHelper.postSendAuthCode(params);
            if (response?.data?.status === 1) {
                setInvalidZipCode(false);
                dispatch(setUserDetailsLoading(false));
                showVerificationCode();
                dispatch(updateTempUserDetails(params));
                setAuthCodeErrorMsg(false);
            } else if (response?.data?.status === 3) {
                dispatch(setUserDetailsLoading(false));
                setInvalidZipCode("Enter a valid Zip code.");
                setAuthCodeErrorMsg(false);
            } else if (response?.data?.status === 4) {
                dispatch(setUserDetailsLoading(false));
                setInvalidZipCode("Enter a valid Zip code.");
                setAuthCodeErrorMsg(response?.data.msg);
            } else {
                setInvalidZipCode(false);
                dispatch(setUserDetailsLoading(false));
                setAuthCodeErrorMsg(response?.data.msg);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function validateEmail() {

        if (!isEmpty(loginEmail)) {
            dispatch(setUserDetailsLoading(true));
            try {
                const response = await apiHelper.postValidateEmail({ email: loginEmail });
                if (response?.data?.status === 1) {
                    setEmailExistMessage("Email address is already registered!");
                    setEmailNotExistMessage(false);
                    setTimeout(() => {
                        setEmailExistMessage(false);
                    }, 5000);
                    dispatch(setUserDetailsLoading(false));
                    return true;
                } else if (response?.data?.status === 2) {
                    setEmailNotExistMessage("Email address is not registered!");
                    setEmailExistMessage(false);
                    setTimeout(() => {
                        setEmailNotExistMessage(false);
                    }, 5000);
                    dispatch(setUserDetailsLoading(false));
                    return false;
                } else {
                    setAuthCodeErrorMsg(response?.data?.msg);
                    dispatch(setUserDetailsLoading(false));
                    return false;
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            return 1;
        }
    }

    const onChangeZip = async (zip) => {
        setZipCode(zip);
        if (zip.length !== 5) {
            setInvalidZipCode("");
        }
        else {
            dispatch(setUserDetailsLoading(true));
            const validZipCode = await isValidateZipcode(zip);
            dispatch(setUserDetailsLoading(false));
            if (validZipCode === false) {
                setInvalidZipCode("Enter a valid Zip code.");
            } else {
                setInvalidZipCode(false);
            }
            setTimeout(() => {

                const input = document.querySelector('.zip-focus');

                // Check if the element exists and has the class
                if (input && input.classList.contains('zip-focus')) {
                    input.focus(); // Give focus if the class is present
                }
            }, 100);
        }
    }

    return (
        displayPopUps === true &&
        <React.Fragment>
            <div className="fade modal-backdrop show"></div>
            <div className="login-popups">
                {statePopUpData?.SignIn && (
                    <SignIn
                        showSignUp={showSignUp}
                        sendAuthCode={sendAuthCode}
                        authCodeErrorMsg={authCodeErrorMsg}
                        closeAllPopUp={closeAllPopUp}
                        setLoginEmail={setLoginEmail}
                        loginEmail={loginEmail}
                        dontWantLogin={dontWantLogin}
                        emailExistMessage={emailExistMessage}
                    />
                )}
                {statePopUpData?.SignUp && (
                    <SignUp
                        showSignIn={showSignIn}
                        sendAuthCode={sendAuthCode}
                        closeAllPopUp={closeAllPopUp}
                        invalidZipCode={invalidZipCode}
                        authCodeErrorMsg={authCodeErrorMsg}
                        loginEmail={loginEmail}
                        setLoginEmail={setLoginEmail}
                        onChangeZip={onChangeZip}
                        zipCode={zipCode}
                        emailNotExistMessage={emailNotExistMessage}
                    />
                )}
                {statePopUpData?.VerificationCode && (
                    <VerificationCode
                        dontWantLogin={dontWantLogin}
                        showSignIn={showSignIn}
                    />
                )}
            </div>
        </React.Fragment>
    );
}