import * as apiHelper from "./api_helper";

export const NumberWithThousandSeparator = (value) => {
  // Ensure the value is a valid number
  const numericValue = typeof value === "number" ? value : parseFloat(value);
  // Format the number with thousand separators
  const formattedNumber = numericValue.toLocaleString();

  return formattedNumber;
};

export const CovertIncentiveFinderByVehicleData = (data) => {
  var table_data_array = [];
  const data_array = Object.entries(data);
  data_array.forEach((item_val) => {
    item_val.forEach((val, key) => {
      if (Array.isArray(val)) {
        val.forEach((incentive_val, incentive_key) => {
          table_data_array.push(incentive_val);
        });
      } else {
        if (item_val[1][0].charging_data.access_to_tesla_supercharger !== undefined) {
          table_data_array.push({
            maker: val,
            nacs_adoption: item_val[1][0].charging_data.nacs_adoption,
            tesla_supercharger_access: item_val[1][0].charging_data.access_to_tesla_supercharger,
          });
        } else {
          table_data_array.push({
            maker: val,
            nacs_adoption: null,
            tesla_supercharger_access: null,
          });
        }
      }
    });
  });
  return table_data_array;
};

export const CovertEVIncentiveFinderData = (incentiveData) => {
  var table_data_array = [];
  if (incentiveData.length > 0) {
    incentiveData?.forEach((makeData) => {
      table_data_array.push({ maker: makeData?.makeName, manufacturer_logo_link: makeData?.manufacturer_logo_link });

      makeData?.modelList.forEach((modelData) => {
        var modelRow = {};
        modelRow['makerModel'] = modelData?.modelName;
        modelRow['totalTrims'] = modelData?.trimList.length;
        modelRow['trimData'] = modelData?.trimList;
        table_data_array.push(modelRow);
      });

    });
  }
  return table_data_array;
};

export const ArrayAsc = (object) =>
  Object.entries(object)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => ({ key, value }));


export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((element, index) => element === arr2[index]);
}

export const getLocalStorageData = (key) => {
  return localStorage.getItem(key);
}

export const setLocalStorageData = (key, values) => {
  localStorage.setItem(key, values);
}

export const removeLocalStorageData = (key) => {
  return localStorage.removeItem(key);
}

export const defaultPopUpState = {
  AskSignIn: false,
  SignIn: false,
  SignUp: false,
  ForgetPassword: false,
  ForgotPasswordConfirm: false,
  CloseALL: false,
  signInSuccessCallback: false
};


/// It will format date for last updated date for incetive.
export const DateFormatterLastUpdated = (value) => {
  var formatted_date = "";
  if (value !== null && value !== "") {
    const dateStr = value;
    const dateObj = new Date(dateStr);

    formatted_date = dateObj.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short", // short for abbreviated month name
      day: "numeric",
      timeZone: "UTC"
    });
  }
  return formatted_date;
};


export const DateFormatterExpirationDate = (value) => {
  let formatted_date = "";

  if (value !== null && value !== "") {
    const dateStr = value;
    const dateObj = new Date(dateStr);

    formatted_date = dateObj.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit", // 2-digit for MM format
      day: "2-digit",   // 2-digit for DD format
      timeZone: "UTC"
    });
  }

  return formatted_date;
};

export const formatDecimal = (value) => {
  // Convert the value to a number in case it's a string
  const number = parseFloat(value);

  // Check if the number is an integer by comparing it to its rounded version
  if (Number.isInteger(number)) {
    return number.toFixed(0); // Return as integer if it's 1.00 (or any other whole number)
  } else {
    return number; // Return the original decimal number
  }
};

export const isEmpty = (value) => {
  if (value === null || value === undefined || value === false || (value === 0 && value !== '0')) {
    return true;
  }
  if (value === true) {
    return false;
  }
  if (value?.trim() === '') {
    return true;
  }
  return false;
}

export const formatPhoneValue = (rawValue) => {
  let formatted = '';
  if (!isEmpty(rawValue)) {
    const cleaned = rawValue.replace(/\D/g, ''); // Remove all non-digit characters

    if (cleaned.length > 0) formatted += '(' + cleaned.substring(0, 3);
    if (cleaned.length >= 4) formatted += ') ' + cleaned.substring(3, 6);
    if (cleaned.length >= 7) formatted += '-' + cleaned.substring(6, 10);
  }

  return formatted;
};

export const isValidateZipcode = async (zipCode) => {

  if (
    zipCode.toString().length > 5 ||
    zipCode.toString().length < 5
  ) {
    return false;
  }

  try {
    const zipcodeResponse = await apiHelper.postValidateZipcode({ zip_code: zipCode });
    if (zipcodeResponse.data.status === 1) {
      return true;
    } else {
      return false
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const handleContextMenu = (event) => {
  event.preventDefault();
};

export const handleDragStart = (event) => {
  event.preventDefault();
};

export const createSeoFriendlyUrl = (urlString) => {
  if(isEmpty(urlString))
  {
    return '';
  }
  return urlString
    .toLowerCase() // Make lowercase for consistency
    .trim() // Remove any extra whitespace at the start and end
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^a-zA-Z0-9-]/g, ''); // Remove any character that's not alphanumeric or a hyphen
}

export const LeaseAmountPerMonthFormate = (value) => {
  // Ensure the value is a valid number
  // const numericValue = typeof value === "number" ? value : parseFloat(value);
  // Format the number with thousand separators
  const formattedNumber = value % 1 === 0 
  ? new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(value)
  : new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(value);

  
  return formattedNumber;
};