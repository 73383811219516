import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import carbg from '../../assets/img/landing-img.png';
import * as Yup from "yup";
import { Formik, Field } from 'formik';
import * as apiHelper from "../../helper/api_helper";
import msgcheck from '../../assets/img/msgcheck.png';
import { withRouter } from "react-router-dom";
import { useMask } from '@react-input/mask';
import { isEmpty, formatPhoneValue, handleDragStart, handleContextMenu } from '../../helper/project_helper';
import { useAuth } from "../../components/hooks/useAuth";
import PageLoaderComponent from "../../components/PageLoaderComponent";

const ContactUs = ({
    history
}) => {
    const [isLogin, userData] = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contectUsFailedErrorMsg, setContectUsFailedErrorMsg] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(formatPhoneValue(userData?.phone_number));
    const [contectUsFailedSuccessMsg, setContectUsFailedSuccessMsg] = useState(false);
    const inputRef = useMask({ mask: '(___) ___-____', replacement: { _: /\d/ } });

    if(isLogin === true)
    {
        
    }
    const goBack = () => {
        history.goBack();  // Navigates to the previous page
        setTimeout(() => {
            window.scrollTo(0, 0); // Add a small delay
        }, 100);
    };

    async function contectUsSave(params = {}) {
        setLoading(true);
        setIsLoading(true);
        if (!isEmpty(phoneNumber)) {
            params.phone_number = phoneNumber.replace(/\D/g, '');
        }
        try {
            const response = await apiHelper.postContectUs(params);
            if (response?.data?.status === 1) {
                setContectUsFailedErrorMsg(false);
                setContectUsFailedSuccessMsg(response?.data?.msg);
                setTimeout(() => {
                    history.push('/', true);
                }, 1500)
                setLoading(false);
                setIsLoading(false);
            } else {
                setLoading(false);
                setIsLoading(false);
                setContectUsFailedErrorMsg(response?.data?.msg);
                setContectUsFailedSuccessMsg(false);
            }
        } catch (error) {
            console.error(error);
            setContectUsFailedErrorMsg("Something went wrong please try again after sometime!");
            setContectUsFailedSuccessMsg(false);
            setLoading(false);
            setIsLoading(false);
        }
    }

    const validationSchema = Yup.object({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),  // Ensures the field is required
        message: Yup.string()
            .required('Message is required'),
    });

    async function getPageMetaData(params = {}) {
        setLoading(true);
        try {
            const response = await apiHelper.postPageMetaData(params);
            if (response?.data?.status === 1) {
                document.title = response?.data?.body?.page_title;
                document.querySelector('meta[name="description"]').setAttribute("content", response?.data?.body?.page_meta_description);

                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getPageMetaData({page_id: 9});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <PageLoaderComponent loading={isLoading} />
            <section className="banner-contact-wapper height1 b-padding">
                <div className="contact-img">
                    <img
                        onContextMenu={handleContextMenu}
                        onDragStart={handleDragStart}
                        src={carbg}
                        alt="" />
                </div>
                <Container>
                    <Row>
                        <Col className="min-margin" lg={{ span: 8, offset: 2 }} style={{ background: "#fff", border: "1px solid #DDE1E6", position: "relative" }}>
                            <div className='popup-close pt-1'>
                                <button onClick={goBack} className="cursor-pointer button-set">
                                    <svg width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#000"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                </button>
                            </div>
                            <div className="">

                                <h1 className="font-big font-wx font-Gunmetal contect-text">Contact Us</h1>
                                <p className="font-midium font-w4 font-parag">Thank you for contacting Siffra. Please let us know below how we can assist you. We can also be reached via email at <span className="text-decoration-underline green-hover-text">support@siffra.com</span><span className="small-space" style={{ marginLeft: "0px" }}>.</span></p>
                                <Formik
                                    initialValues={{ first_name: userData?.first_name || '', last_name: userData?.last_name, email: userData?.email, message: '' }}
                                    validationSchema={validationSchema}  // Pass the validation schema
                                    onSubmit={async (values, { resetForm }) => {
                                        await contectUsSave(values);
                                        setPhoneNumber('');
                                        resetForm();
                                    }}
                                >
                                    {({ handleSubmit, values, errors, touched }) => (
                                        <Form className="mt-3" onSubmit={handleSubmit}>
                                            <Row className="" style={{ marginBottom: "2rem" }}>
                                                <Form.Group as={Col} xs="6" controlId="validationCustom01" className="mb-3">
                                                    <Form.Label className="font-midium font-w text-detaile m-0">First name</Form.Label><span className="ms-1">*</span>
                                                    <Field
                                                        type="text"
                                                        name="first_name"
                                                        disabled={loading}
                                                        className={errors.first_name && touched.first_name ? "error-border border-w form-control" : "border-w form-control"}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} xs="6" controlId="validationCustom02" className="mb-3">
                                                    <Form.Label className="font-midium text-detaile font-w m-0">Last name</Form.Label><span className="ms-1">*</span>
                                                    <Field
                                                        type="text"
                                                        disabled={loading}
                                                        className={errors.last_name && touched.last_name ? "error-border border-w form-control" : "border-w form-control"}
                                                        name="last_name"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} xs="12" controlId="validationCustom02" className="mb-3">
                                                    <Form.Label className="font-midium text-detaile font-w m-0">Email</Form.Label><span className="ms-1">*</span>
                                                    <Field
                                                        type="text"
                                                        disabled={loading}
                                                        className={errors.email && touched.email ? "error-border border-w form-control" : "border-w form-control"}
                                                        name="email"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} xs="12" controlId="validationCustom02" className="mb-3">
                                                    <Form.Label className="font-midium text-detaile font-w m-0">Phone Number</Form.Label>
                                                    <input
                                                        type="text"
                                                        onChange={(x) => setPhoneNumber(x.target.value)}
                                                        ref={inputRef}
                                                        disabled={loading}
                                                        value={phoneNumber}
                                                        className="border-w form-control font-wxl font-color"
                                                        name="phone_number"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} xs="12" controlId="validationCustom02">
                                                    <Form.Label className="font-midium text-detaile font-w m-0">Message</Form.Label><span className="ms-1">*</span>
                                                    <Field
                                                        as="textarea"
                                                        style={{ height: '80px' }}
                                                        name="message"
                                                        disabled={loading}
                                                        className={errors.message && touched.message ? "error-border border-w form-control" : "border-w form-control"}
                                                    />

                                                </Form.Group>
                                            </Row>
                                            <div className="text-end">
                                                {contectUsFailedErrorMsg && (
                                                    <Fragment>
                                                        <p className='text-danger float-left'>{contectUsFailedErrorMsg}</p>
                                                    </Fragment>
                                                )}
                                                {contectUsFailedSuccessMsg && (
                                                    <Fragment>
                                                        <div className="float-left msg-note py-2 d-flex align-items-center justify-content-between px-2 mb-4 w-75" style={{ background: "#000" }}>
                                                            <div className="d-flex align-items-center">
                                                                <img className="me-3"
                                                                    onContextMenu={handleContextMenu}
                                                                    onDragStart={handleDragStart}
                                                                    src={msgcheck}
                                                                    alt=""
                                                                /><p className="warning-msg font-white">{contectUsFailedSuccessMsg}</p>

                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )}
                                                <Button type="submit"
                                                    className="btn-learn-more "
                                                    disabled={isEmpty(values?.first_name) || isEmpty(values?.last_name) || isEmpty(values?.email) || errors.email || isEmpty(values?.message) || loading}
                                                >Send</Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default withRouter(ContactUs);