import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Form, Offcanvas } from 'react-bootstrap';
import { isEmpty } from "../../helper/project_helper";
import { useMask } from '@react-input/mask';

const Mobile = ({
    show,
    handleClose,
    filterList,
    onChangeFilter,
    value,
    onSearchClick,
    onResetClick,
    handleZipBlur,
    zipCodeError,
    blankFilterError,
    filterloading
}) => {

    const inputRefZip = useMask({ mask: '_____', replacement: { _: /\d/ } });

    return (
        <Col className="" lg={3}>
            <Offcanvas show={show} onHide={handleClose} placement="start">
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body className="py-0">
                    <div className="" style={{ background: "#fff" }}>
                        <div className="mb-2 mt-3">
                            <label className="mb-1 font-midium font-w text-detaile">Zip Code</label>
                            <Form.Control
                                className={"border-w height-set text-font " + (zipCodeError && " error-border")}
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={value?.zip_code}
                                ref={inputRefZip}
                                onChange={(x) => onChangeFilter("zip_code", x.target.value)}
                                onBlur={handleZipBlur}
                                disabled={filterloading}
                            />
                            <span style={{ fontSize: "14px" }} className="mt-5">
                                {zipCodeError ? "Enter a valid Zip code." : ""}{" "}
                            </span>
                        </div>
                        <div className="mb-2">
                            <label className="mb-1 font-midium font-w text-detaile">Make*</label>
                            <Form.Select
                                className={"height-set text-font " + (blankFilterError?.make === true && "error-border")}
                                aria-label="Default select example"
                                value={value?.make}
                                disabled={filterloading}
                                onChange={(x) => onChangeFilter("make", x.target.value)}
                            >
                                <option value="">Select</option>
                                {filterList?.make && filterList?.make?.length > 0 && (
                                    filterList?.make?.map((value, key) => {
                                        return (
                                            <option key={key} value={value}>{value}</option>
                                        );
                                    })
                                )}
                            </Form.Select>
                        </div>
                        <div className="mb-2">
                            <label className="mb-1 font-midium font-w text-detaile">Model*</label>
                            <Form.Select
                                className={"height-set text-font " + (blankFilterError?.model === true && "error-border")}
                                aria-label="Default select example"
                                value={value?.model}
                                disabled={filterloading}
                                onChange={(x) => onChangeFilter("model", x.target.value)}
                            >
                                <option value={""}>Select</option>
                                {filterList?.model && filterList?.model?.length > 0 && (
                                    filterList?.model?.map((value, key) => {
                                        return (
                                            <option key={key} value={value}>{value}</option>
                                        );
                                    })
                                )}
                            </Form.Select>
                        </div>
                        <div className="mb-2">
                            <label className="mb-1 font-midium font-w text-detaile">Model Year*</label>
                            <Form.Select
                                className={"height-set text-font " + (blankFilterError?.model_year === true && " error-border ")}
                                aria-label="Default select example"
                                value={value?.model_year}
                                disabled={filterloading}
                                onChange={(x) => onChangeFilter("model_year", x.target.value)}
                            >
                                <option value={""}>Select</option>
                                {filterList?.model_year && filterList?.model_year?.length > 0 && (
                                    filterList?.model_year?.map((value, key) => {
                                        return (
                                            <option key={key} value={value}>{value}</option>
                                        );
                                    })
                                )}
                            </Form.Select>
                        </div>
                        <div className="mb-2">
                            <label className="mb-1 font-midium font-w text-detaile">Trim*</label>
                            <Form.Select
                                className={"height-set text-font " + (blankFilterError?.trim === true && "error-border")}
                                aria-label="Default select example"
                                value={value?.trim}
                                disabled={filterloading || filterList?.trim?.length === 0}
                                onChange={(x) => onChangeFilter("trim", x.target.value)}
                            >
                                <option value={""}>Select</option>
                                {filterList?.trim && filterList?.trim?.length > 0 && (
                                    filterList?.trim?.map((value, key) => {
                                        return (
                                            <option key={key} value={value}>{value}</option>
                                        );
                                    })
                                )}
                            </Form.Select>
                        </div>
                        <div className="mb-2">
                            <label className="mb-1 font-midium font-w">Purchase Type*</label>
                            <div className="d-flex">
                                <Form.Check
                                    className="radio-check w-25 font-wx"
                                    checked={value?.purchase_type === "Buy"}
                                    label="Buy"
                                    value="Buy"
                                    disabled={filterloading}
                                    onChange={(x) => onChangeFilter("purchase_type", x.target.value)}
                                />
                                <Form.Check
                                    checked={value?.purchase_type === "Lease"}
                                    className="radio-check font-wx"
                                    value="Lease"
                                    label="Lease"
                                    disabled={filterloading}
                                    onChange={(x) => onChangeFilter("purchase_type", x.target.value)}
                                />
                            </div>
                        </div>
                        <div className="mt-3 pt-1  ">
                            <button
                                disabled={filterloading || isEmpty(value?.zip_code) || isEmpty(value?.make) || isEmpty(value?.model) || isEmpty(value?.model_year) || (filterList?.trim?.length > 0 && isEmpty(value?.trim))}
                                className="btn-learn-more text-center w-100"
                                onClick={onSearchClick}
                            >Find
                            </button>
                        </div>
                        <div className="mt-4">
                            <button
                                className="text-dark font-midium font-wx btn-reset"
                                onClick={onResetClick}
                            >Reset
                            </button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </Col>
    );
}

export default withRouter(Mobile);